<template>
	<div>
		<v-data-table
			:headers="requestHeaders"
			:items="requestList"
			:items-per-page="15"
			:loading="isLoading"
			@click:row="handleClick"
			:item-class="itemRowBackground"
			class="elevation-1 request_list">
			<template v-slot:item.decision="{ item }">
				{{ decision[item.decision] }}
			</template>
			<template v-slot:item.decision="{ item }">
				<template v-if="item.decision == 0">
					<v-btn depressed color="primary">обработать</v-btn>
				</template>
				<template v-else>
					{{decision[item.decision]}}
				</template>
			</template>
		</v-data-table>
	</div>
</template>

<script>
	export default {
        mounted() {
            this.getData();
        },
		data: () => ({
			isLoading: true,
			decision: ['Нет решения', 'Положительно', 'Отрицательно'],
			requestHeaders: [],
			requestList: []
		}),
		methods: {
			handleClick(item) {
				if(item.decision != 0) {
					this.$router.push({ path: '/department/request/'+item.id });
				} else {
					this.$router.push({ path: '/department/request/next' });
				}
			},
			itemRowBackground: function (item) {
				let rowCLass = '';
				if(item.decision == '1') {
					rowCLass = 'light-green lighten-4';
				} else if(item.decision == '2') {
					rowCLass = 'red lighten-5';
				}
				return rowCLass;
			}, 
			async getData() {
				await this.$http.get('/request/all?currPage=1&limit=5').then((response) => {
					this.requestHeaders = response.data.data.requestList.headers;
					this.requestList = response.data.data.requestList.list;
					this.isLoading = false;
				});
			}
			
		}
  }
</script>

<style scoped>
	/*.request_list tr {
		cursor: pointer !important;
	}*/
</style>